@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Poppins", "sans-serif";
  }
}
.text-shadow-one {
  text-shadow: 3px 3px 1px rgba(0, 0, 0, 0.4);
}
.black h3 {
  color: #000;
}
.tree-rw.two {
  max-width: 422px;
}
.mobile-bar:hover svg {
  stroke: #fae676;
}
.main-menu li.active a,
.main-menu li a:hover {
  color: #fae676;
}